/** @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import Img from "gatsby-image";
import { Lightbox } from "react-modal-image";
// import ModalVideo from 'react-modal-video';
import useImageGallery from "../hooks/useImageGallery";
// import useVideoGallery from "../hooks/useVideoGallery";
import Grid from "./Grid";
import Tile from "./Tile";


import videoOne from "../../content/videos/001-XQME4281H.mp4";
import videoTwo from "../../content/videos/002-KRDV0547H.mp4";
import videoThree from "../../content/videos/003-XQME4604H.mp4";
import videoFour from "../../content/videos/004-KRDV0628H.mp4";
import videoFive from "../../content/videos/005-KRDV1975H.mp4";
import videoSix from "../../content/videos/006-Spring-mix.mp4";
import videoSeven from "../../content/videos/007-Turtles.mp4";
import videoEight from "../../content/videos/008-KRDV0699H_AppleJourney.mp4";
import videoNine from "../../content/videos/009-XQME4222H.mp4";


const imgStyles: any = {
  css: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    // height: "100%",
    transition: "transform 0.5s, filter 0.25s",
    "&:hover": {
      transform: "scale(1.1)",
      // filter: "saturate(1.3)",
    },
    "--aspect-ratio":"16/9"
  },
};

const Gallery = () => {
  const images = useImageGallery();
  const videos = [videoOne, videoTwo, videoThree, videoFour, videoFive, videoSix, videoSeven, videoEight, videoNine];



  const [showImageIndex, setShowImageIndex] = React.useState<
    number | undefined
  >(undefined);

  return (
    <div>
      <Grid>
        {images.map((image, index) => (
          <Tile
            key={image.id}
            onClick={() => {
              setShowImageIndex(index);
            }}
          >
            <Img alt={image.name} fluid={image.fluid} {...imgStyles} />
          </Tile>
        ))}
      </Grid>
      {showImageIndex !== undefined && (
        <Lightbox
          large={videos[showImageIndex]}
          hideDownload={true}
          hideZoom={true}
          posterSrc={images[showImageIndex].publicURL}
          // large={images[showImageIndex].publicURL}
          onClose={() => {
            setShowImageIndex(undefined);
          }}
        />
      )}
    </div>
  );
};

export default Gallery;
