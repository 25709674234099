import styled from "@emotion/styled";

const Grid = styled.div`
  display: grid;
  grid-gap: 0rem;
  padding-bottom: 4rem;
  grid-template-columns: 33% 33% 33%;

  @media(max-width: 760px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`;

//original styles
// const Grid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
//   grid-auto-rows: 16rem;
//   grid-gap: 0.5rem;

//   @media (min-width: 1280px) {
//     grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
//     grid-auto-rows: 24rem;
//   }
// `;

export default Grid;
